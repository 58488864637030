import React from 'react';

import "./pricecontainer.scss"


const PriceContainer = ({ content }) => {
    return (
        <div className="PriceContainer">
            {content}
        </div>
    );
};


export default PriceContainer





