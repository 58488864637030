import React from 'react'
import LeftArrow from "../../assets/images/left-arrow.svg"

import "./backnav.scss"

const Back = ({ onClick }) => {
  return (
      <div className="BackNavWrapper" onClick={onClick}>
          <img src={LeftArrow} alt="" height="20px"/>
          <p>Back</p>
      </div>
    
  )
}

export default Back