import React from 'react';
import "./buysell.scss"

const BuySellText = ({ buyOnly, usd }) => {
    return (
        <div className="BuySellTextWrapper">
            {buyOnly ? (
                <p style={{ paddingBottom: "0px" }}>Buy</p>)
                : usd ? (
                    <>
                        <p style={{ paddingBottom: "0px" }}>USD</p>
                    </>
                ) :(
                    <>
                    <p>Buy</p>
                    <p>Sell</p>
                </>
                )}
        </div>
    );
};

export default BuySellText;
