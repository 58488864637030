import React from "react";
import Logo from "../../assets/images/kudi-logo.svg";
import WhiteLogo from "../../assets/images/kudi-logo-white.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  //   DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  MenuItemOption,
  // MenuGroup,
  MenuOptionGroup,
//   MenuDivider,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/react";

import "./header.scss";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  let navigate = useNavigate();

  return (
    <nav className="HeaderWrapper">
      <Link to="/">
        <img src={Logo} alt="KudiFx Logo" className="Logo" />
      </Link>

      {/* Desktop Menu */}
      <div className="NavMenuWrapper">
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            colorScheme="white"
          >
            Rates
          </MenuButton>
          <MenuList>
            <Link to="/" className="DropdownMenu">
              <MenuItem>Black Market Rates</MenuItem>
            </Link>
            <Link to="/atm-rates" className="DropdownMenu">
              <MenuItem>ATM Rates</MenuItem>
            </Link>
            <Link to="cbn-rates" className="DropdownMenu">
              <MenuItem>CBN Rates</MenuItem>
            </Link>
          </MenuList>
        </Menu>
        <Link to="/crypto-rates" className="NavLinks">
          Crypto
        </Link>
        <Link to="/news" className="NavLinks">
          News
        </Link>
        <Link to="/about" className="NavLinks">
          About
        </Link>
        <Link to="/contact" className="NavLinks">
          Contact
        </Link>
      </div>

      {/* Mobile Menu */}
      <Button
        ref={btnRef}
        as={IconButton}
        onClick={onOpen}
        icon={<HamburgerIcon />}
        variant="outline"
      ></Button>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody style={{ color: "white" }}>
            <Menu>
              <MenuOptionGroup className="MenuGroupTitle" title="Rates" isChecked={false}>
                <MenuItemOption
                  className="MenuItemOption"
                  value="email"
                  isChecked={false}
                  onClick={() => {
                    navigate("/");
                    onClose();
                  }}
                >
                  Black Market
                </MenuItemOption>
                
                <MenuItemOption
                  className="MenuItemOption"
                  value="phone"
                  isChecked={false}
                  onClick={() => {
                    navigate("/cbn-rates");
                    onClose();
                  }}
                >
                  CBN
                </MenuItemOption>
                <MenuItemOption
                  className="MenuItemOption"
                  value="country"
                  isChecked={false}
                  onClick={() => {
                    navigate("/atm-rates");
                    onClose();
                  }}
                >
                  ATM
                </MenuItemOption>
              </MenuOptionGroup>

              <MenuItem
                className="OtherMenuItemsCrypto"
                onClick={() => {
                  navigate("/crypto-rates");
                  onClose();
                }}
              >
                Crypto
              </MenuItem>

              <MenuItem
                className="OtherMenuItemsCrypto"
                onClick={() => {
                  navigate("/news");
                  onClose();
                }}
              >
                News
              </MenuItem>

              <MenuItem
                className="OtherMenuItems"
                isChecked={false}
                onClick={() => {
                  navigate("/about");
                  onClose();
                }}
              >
                About
              </MenuItem>
              <MenuItem
                className="OtherMenuItems"
                isChecked={false}
                onClick={() => {
                  navigate("/contact");
                  onClose();
                }}
              >
                Contact
              </MenuItem>
            </Menu>
          </DrawerBody>

          <DrawerFooter className="MenuFooterWrapper">
            <Link to="/">
              <img
                src={WhiteLogo}
                alt="KudiFX Logo"
                height="35px"
                className="FooterMenuLogo"
                onClick={onClose}
              />
            </Link>

            <div>
              <p className="FooterMenuNote">
                © 2022 Kudi FX • All rights reserved
              </p>
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </nav>
  );
};

export default Header;
