import React, { useEffect, useState } from 'react';
import PriceContainer from "../../components/PriceContainer";
import axios from "axios";
import moment from 'moment';

import "./atmrates.scss"

const AtmRates = () => {

    const [ratesDash, setRatesDash] = useState([])
    const [rates, setRates] = useState([
        {
            bank: "UBA",
            location: "ONLINE",
            date: "2023/01/16",
            currency: "USD",
            value: "503"
        },
        {
            bank: "ECOBANK",
            location: "ONLINE",
            date: "2022/03/23",
            currency: "USD",
            value: "480"
        },
        {
            bank: "ZENITH",
            location: "ONLINE",
            date: "2023/01/16",
            currency: "USD",
            value: "416"
        },
        {
            bank: "ACCESS",
            location: "ONLINE",
            date: "2022/12/03",
            currency: "USD",
            value: "505"
        },
        {
            bank: "GTB",
            location: "ONLINE",
            date: "2022/10/16",
            currency: "USD",
            value: "490"
        },
        {
            bank: "FIRST BANK",
            location: "UK",
            date: "2022/09/05",
            currency: "GBP",
            value: "706"
        },
        {
            bank: "FIRST BANK",
            location: "ONLINE",
            date: "2022/07/18",
            currency: "CAD",
            value: "466"
        },
        {
            bank: "FIRST BANK",
            location: "ONLINE",
            date: "2022/07/18",
            currency: "EUR",
            value: "602"
        }
    ])
    const [server, setServer] = useState("External")

    // console.log(moment.utc("2022-02-28T19:50:01.198Z").format("YYYYMMDD"))
    // const duration = moment("20220228", "YYYYMMDD").fromNow();
    // console.log(moment().subtract(duration, 'day').format("YYYYMMDD"))


    // const formatPostedDate = (date) => {
    //     let subtractOneHour = moment(date).subtract(1, 'days')
    //     subtractOneHour.format("YYYYMMDD")
    // }

    const GetAtmRates = async () => {
        try {
            const atmRatesData = await axios.get("https://kudifx.herokuapp.com/api/v1/get-price")

            const getServer = await axios.get("https://kudifx.herokuapp.com/api/v1/get-server")

            setServer(getServer.data)

            // setServer("External")

            // console.log(atmRatesData.data)
            setRatesDash(atmRatesData.data.filter(i => i.atm === true))

        } catch (error) {
            return error
        }
    }

    const GetAtmRatesEx = async () => {
        try {
            const atmRatesData = await axios.get("https://abokiforexpaid.appspot.com/_ah/api/atmApi/v1/atmrateitemcollection?alt=json")

            console.log(atmRatesData)

            // setRates(atmRatesData.data.items)

            setRates({
                bank: "UBA",
                location: "ONLINE",
                date: "2023/01/16",
                currency: "USD",
                value: "503"
            },
            {
                bank: "ECOBANK",
                location: "ONLINE",
                date: "2022/03/23",
                currency: "USD",
                value: "480"
            },
            {
                bank: "ZENITH",
                location: "ONLINE",
                date: "2023/01/16",
                currency: "USD",
                value: "416"
            },
            {
                bank: "ACCESS",
                location: "ONLINE",
                date: "2022/12/03",
                currency: "USD",
                value: "505"
            },
            {
                bank: "GTB",
                location: "ONLINE",
                date: "2022/10/16",
                currency: "USD",
                value: "490"
            },
            {
                bank: "FIRST BANK",
                location: "UK",
                date: "2022/09/05",
                currency: "GBP",
                value: "706"
            },
            {
                bank: "FIRST BANK",
                location: "ONLINE",
                date: "2022/07/18",
                currency: "CAD",
                value: "466"
            },
            {
                bank: "FIRST BANK",
                location: "ONLINE",
                date: "2022/07/18",
                currency: "EUR",
                value: "602"
            })


        } catch (error) {
            return error
        }
    }

    useEffect(() => {
        GetAtmRates()
        GetAtmRatesEx()
    }, [])

    if (server === "External") {
        return (
            <div className="CbnPageWrapper">
                <h1 className="CbnPageTitle">ATM Rates</h1>

                {rates.map((rate, index) => {
                    return (
                        <PriceContainer
                            content={
                                <div key={index} className="BmPriceContainerWrapper">
                                    <div className="AtmCurrencyWrapper">
                                        <p className="AtmCurrencyName">{rate.bank} <span>{`(${rate.location})`}</span> </p>
                                        {/* Fix to subtract 1 day added from results. API returned 1 extra day */}
                                        <p className="AtmCurrencyLastUpdate">{moment(`${rate.date}`, "YYYYMMDD").subtract(-1, 'days').fromNow()}
                                        </p>
                                    </div>


                                    <p className="AtmCurrencySymbol">{rate.currency}</p>

                                    <div className="AtmCurrencyPrice">
                                        <p>&#8358;{rate.value}</p>
                                    </div>

                                </div>}
                        />
                    )
                })}
            </div>

        );
    }

    return (
        <div className="CbnPageWrapper">
            <h1 className="CbnPageTitle">ATM Rates</h1>

            {ratesDash && ratesDash.map((rate, index) => {
                return (
                    <PriceContainer
                        content={
                            <div key={index} className="BmPriceContainerWrapper">
                                <div className="AtmCurrencyWrapper">
                                    <p className="AtmCurrencyName">{rate.currency_name}
                                        {/* <span>{`(${rate.location})`}</span>  */}
                                    </p>
                                    {/* Fix to subtract 1 day added from results. API returned 1 extra day */}
                                    <p className="AtmCurrencyLastUpdate">{moment(`${rate.updatedAt}`, "YYYYMMDD").fromNow()}
                                    </p>
                                </div>


                                <p className="AtmCurrencySymbol">{rate.currency_symbol}</p>

                                <div className="AtmCurrencyPrice">
                                    <p>&#8358;{rate.buy_price}</p>
                                </div>

                            </div>}
                    />
                )
            })}
        </div>


    );
};

export default AtmRates;
