import React, { useEffect, useState } from 'react';
import PriceContainer from "../../components/PriceContainer";
import axios from "axios";
import Dollars from "../../assets/images/us-dollars.svg"
import Pounds from "../../assets/images/pounds.svg"
import Euros from "../../assets/images/euros.svg"


import "./cbnrates.scss"

const CbnRates = () => {

    const [rates, setRates] = useState(null)

    const GetCbnRates = async () => {
        try {
            const getCbnRatesData = await axios.get('https://kudifx.herokuapp.com/api/v1/cbn-rate')

            setRates(getCbnRatesData.data);
        } catch (error) {
            return error
        }
    }


    useEffect(() => {
        GetCbnRates()
    }, [])





    return (
        <div className="CbnPageWrapper">
            <h1 className="CbnPageTitle">CBN Rates</h1>
            {
                rates && (
                    <div>
                        <PriceContainer
                            content={
                                <div className="BmPriceContainerWrapper">
                                    <div className="CurrencyWrapper">
                                        <img src={Dollars} alt="Rates Icon" height="70px" />
                                        <p className="CurrencyName">Dollar (USD)</p>
                                    </div>

                                    <div className="CurrencyPrice">
                                        <p>&#8358;{rates.usd}</p>
                                    </div>
                                </div>}
                        />

                        <PriceContainer
                            content={
                                <div className="BmPriceContainerWrapper">
                                    <div className="CurrencyWrapper">
                                        <img src={Pounds} alt="Rates Icon" height="70px" />
                                        <p className="CurrencyName">Pound (GBP)</p>
                                    </div>

                                    <div className="CurrencyPrice">
                                        <p>&#8358;{rates.gbp}</p>
                                    </div>
                                </div>}
                        />

                        <PriceContainer
                            content={
                                <div className="BmPriceContainerWrapper">
                                    <div className="CurrencyWrapper">
                                        <img src={Euros} alt="Rates Icon" height="70px" />
                                        <p className="CurrencyName">Euro (EUR)</p>
                                    </div>

                                    <div className="CurrencyPrice">
                                        <p>&#8358;{rates.eur}</p>
                                    </div>
                                </div>}
                        />
                    </div>
                )
            }
        </div>


    );
};

export default CbnRates;
