import React, { useEffect, useState } from 'react';
import PriceContainer from "../../components/PriceContainer";
// import { CrytoRatesData } from "./data";
import axios from 'axios'

import "./cryptorates.scss"

const CryptoRates = () => {

    const [coins, setCoins] = useState([]);

    const GetApiPrices = async () => {
        try {
            // const prices = await axios.get("https://api.nomics.com/v1/currencies/ticker?key=70d8ce6aebb20919b9c8650ccd61cbce24192507&currency=BTC") 
            // const coin = await axios.get("https://api.coingecko.com/api/v3/coins") 
            // setCoins(coin.data)
            const coin = await axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false")
            setCoins(coin.data)
        } catch (error) {
            return (error)
        }
    }

    
    // const filterCurrency = () => {
    //     const words = ("btc", "eth", "ltc", "bch")

    //     const filters = coins.map(coin => coin.symbol).filter(coin => coin === words)
    //     console.log('fil', filters)
    // } 
    // filterCurrency()

    const currencyFormatter = value => {
        let formattedValue = parseFloat(value, 10)
            ?.toFixed(3)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        return formattedValue
    }

    

    useEffect(() => {
        GetApiPrices()
    }, [])


    return (
        <div className="CbnPageWrapper">
            <h1 className="CbnPageTitle">Cryptocurrency</h1>

            {coins.map((coin, index) => {
                return (
                    <PriceContainer
                        content={
                            <div key={index} className="BmPriceContainerWrapper">
                                <div className="CurrencyWrapper">
                                    <img src={coin.image} alt="Rates Icon" height="70px" />
                                    <div className="CryptoCurrencyNameWrapper">
                                        <p className="CryptoCurrencyName">{`1 ${coin.name}`}</p>
                                        <p className="CryptoCurrencySubName">{coin.subName}</p>
                                    </div>
                                    
                                </div>


                                <div className="CryptoCurrencyPrice">
                                    <p>{`$${currencyFormatter(coin.current_price)}`}</p>
                                </div>
                            </div>}
                    />
                )
            })}
        </div>


    );
};

export default CryptoRates;
