import React from 'react';
import "./newscontainer.scss"

const NewsContainer = ({ content, onClick }) => {
    return (
        <div className="NewsContainer" onClick={onClick}>
            {content}
        </div>
    );
};

export default NewsContainer

