import React from 'react';

import "./contact.scss"

const ContactPage = () => {


    // const object = {
    //     '2021': {
    //         Jan_2021: {
    //             approved: 0,
    //             denied: 0,
    //             inReview: 0,
    //         },
    //         Feb_2021: {
    //             approved: 0,
    //             denied: 0,
    //             inReview: 0,
    //         },
    //     },
    //     '2022': {
    //         Jan_2022: {
    //             approved: 0,
    //             denied: 0,
    //             inReview: 0,
    //         },
    //         Feb_2022: {
    //             approved: 0,
    //             denied: 0,
    //             inReview: 0,
    //         },
    //     },
    // }

    // const first = Object.values(object)
    // const mapFirst = first.map(x => x)
    // console.log(mapFirst)




    return (
        <div className="ContactPageWrapper">
            <div>
                <h1 className="ContactPageTitle">Contact</h1>

                <div className="ContactTextWrap">
                    <p className="ContactTextHead">
                        Enquiries
                    </p>
                    <p className="ContactSubText">Info.kudifx.com</p>
                </div>
                <div className="ContactTextWrap">
                    <p className="ContactTextHead">
                        Rates
                    </p>
                    <p className="ContactSubText">Rates.kudifx.com</p>
                </div>
                <div className="ContactTextWrap">
                    <p className="ContactTextHead">
                        Research
                    </p>
                    <p className="ContactSubText">Research.kudifx.com</p>
                </div>

            </div >
        </div>


    );
};

export default ContactPage;
