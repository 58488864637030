import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { HashRouter } from "react-router-dom";
import { ScrollToTop } from "./ScrollToTop";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider resetCSS={false}>
      <HashRouter>
        <ScrollToTop />
        <App />
      </HashRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
