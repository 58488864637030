import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import NewsContainer from "../../components/NewsContainer";
import axios from "axios";
import moment from 'moment';

import "./news.scss";
import NewsDetail from "../NewsDetail";

const News = () => {
  const [news, setNews] = useState([]);
  const [title, setTitle] = useState("");
  const [source, setSource] = useState("");
  const [publishAt, setPublishAt] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState("List")

  const fetchNews = async () => {
    try {

      // const mediaStackNews = await axios.get("http://api.mediastack.com/v1/news?access_key=f62b1941f34969f50191e58177bba792&countries=ng,us&categories=business,technology&keywords=nigeria&sort=published_desc")

      //   const mediaStackNews = await axios.get({
      //     url: "https://api.mediastack.com/v1/news",
      //     data: {
      //       access_key: '9118d83348cb1806ce4773f70c311d28',
      //       languages: 'fr,-en',
      //       countries: 'ng',
      //       limit: 30,
      //       offset: 30,
      //     }
      // })
      // let num = Math.floor(Math.random() * (8 - 1 + 1) + 1)

      // setCat1(num % 2 === 0 ? "world" : "technology")
      //https://newsdata.io/api/1/news?apikey=pub_72923f07282dc8e1ce33feacd6823d57cd8e

      // setTimeout( async () => {
        
        const mediaStackNews = await axios.get(`https://newsdata.io/api/1/news?apikey=pub_72923f07282dc8e1ce33feacd6823d57cd8e&country=ng,za&category=business,technology&language=en&q=bank%20OR%20cbn%20OR%20stock%20OR%20forex%20OR%20crypto%20OR%20cryptocurrency%20OR%20finance%20OR%20money`)
       
        // console.log(mediaStackNews.data.results)
        // setNews(mediaStackNews.data.results);
      // }, 1000)

      const mediaStackNews2 = await axios.get(`https://newsdata.io/api/1/news?apikey=pub_75566fe3fbcb84118b1965176dab5b4e3a7f&country=us,ae,gb&category=business,technology,politics&language=en&q=bank%20OR%20fund%20OR%20stock%20OR%20forex%20OR%20crypto%20OR%20cryptocurrency%20OR%20finance%20OR%20money`)
       
        // console.log([...mediaStackNews.data.results, ...mediaStackNews2.data.results])
        setNews([...mediaStackNews.data.results, ...mediaStackNews2.data.results]);

      // setTimeout( async () => { pub_75566fe3fbcb84118b1965176dab5b4e3a7f
      //     console.log(num)
      // const mediaStackNews = await axios.get(`https://newsapi.org/v2/everything?q=${num === 1 ? "banks" : num === 2 ? "forex" : num === 3 ? "naira" : num === 4 ? "cryptocurrency": num === 5 ? "dollar" : num === 6 ? "nigeria stocks" : num === 7 ? "atm" : num === 8 && "cbn" }&apiKey=3e213b8195b84bf686ff605e8ec99bfd`)
      // console.log(mediaStackNews.data.articles)
      // setNews(mediaStackNews.data.articles);
      //  }, 1000)
       
      // 3e213b8195b84bf686ff605e8ec99bfd

      // https://newsapi.org/v2/everything?q=bitcoin&apiKey=3e213b8195b84bf686ff605e8ec99bfd

      // ${num === 1 && "banks" || num === 2 && "forex" || num === 3 && "naira" || num === 4 && "cryptocurrency" || num === 5 && "dollar" || num === 6 && "nigeria stocks" || num === 7 && "atm" || num === 8 && "cbn" }
      // pub_72923f07282dc8e1ce33feacd6823d57cd8e

      //   https://api.mediastack.com/v1/news
      // ? access_key = YOUR_ACCESS_KEY
      // & keywords = tennis
      // & countries = us, gb, de

      // const mediaStackNews = await axios.get("http://api.mediastack.com/v1/news?access_key=f62b1941f34969f50191e58177bba792&countries=ng&categories=business&limit=100&keywords=nigeria&sort=published_desc")
      // console.log(mediaStackNews.data.articles)
      // setNews(mediaStackNews.data.articles);

      // const abokiNews = await axios.get(
      //   "https://abokiforexpaid.appspot.com/_ah/api/myFeed/v1/feeditemcollection/1641156380608?alt=json"
      // );
      //   console.log(abokiNews.data.items);
      //       console.log(getNews.data.articles);
      //   setNews(getNews.data.articles);
      // console.log(abokiNews.data.items)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const back = () => {
    setPage("List")
  }

  // let navigate = useNavigate();

  if (page === "Details"){
    return <NewsDetail title={title} source={source} publishe_at={publishAt} description={description} back={back} />
  }

  return (
    <div className="NewsPageWrapper">
      <>
        <div>
          <h1 className="NewsPageTitle">Forex News</h1>

          {news.map((item) => {
            return (
              <NewsContainer
                // onClick={() => {
                //   navigate(item.link);
                //   console.log(item.link)
                // }}
                // onClick={() => {
                // // window.location.href = `${item.link}`;
                // window.open(`${item.link}`, '_blank'); 
                // }}
                key={item.title}
                onClick={() => {
                  // navigate(`/news/${item.description}`)
                  setTitle(item.title)
                  setSource(item.link)
                  setPublishAt(item.pubDate)
                  setDescription(item.content !== null ? item.content : item.description)
                  setPage("Details")
                }}
                content={
                  <div key={item.date} className="NewsMainWrapper">
                    <div className="NewsWrapperContainer">
                      <div className="NewsWrapper">
                        <p className="NewsTitle">{item.title}</p>
                        <div className="NewsSubDetailsWrapper">
                          <p className="NewsSource">{item.link.slice(item.link.indexOf("w"), 30)}</p>
                          &nbsp;
                          |
                          &nbsp;
                          <p className="NewsLastUpdated"> {`${moment(item.pubDate).startOf('hour').fromNow()}`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            );
          })}
        </div>
      </>
    </div>
  );
};

export default News;
