import React from 'react';

import "./about.scss"

const AboutPage = () => {


    return (
        <div className="AboutPageWrapper">
                <div>
                    <h1 className="AboutPageTitle">About</h1>

                    <p className="AboutText">
                    We offer the most up-to-date, reputable currency information and provide you with secure, dependable, and simple-to-use products and services aimed at making your life easier.
                    </p>
                </div >
        </div>


    );
};

export default AboutPage;
