import React from "react";
// import { useParams } from "react-router";
// import { useNavigate } from "react-router-dom";
import BackNav from "../../components/BackNav";
// import axios from "axios";
// import Iframe from "react-iframe";
import moment from "moment";

import "./newsdetail.scss";

const NewsDetail = (props) => {
  // const [news, setNews] = useState([]);
  // const iref = useRef(null);
  // const [height, setHeight] = useState("100%");

  // const onLoad = () => {
  //   setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  //   console.log({frame: ref.current.contentWindow.document.body})
  // };

 
  // function contentToHtml(text) {
  //   return text
  //     .split("\n\n")
  //     .map((paragraph) => `<p>${paragraph}</p>`)
  //     .join("");
  // }

  // const FreshContent = () => {
  // const regex = /(<([^>]+)>)/gi;
  // const newContent = news.map((item) => item.content.replace(regex, ""));
  //   return (
  //     <>
  //       {newContent.map((content) => {
  //         return (
  //           <>
  //             <p>{content}</p>
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

  // let navigate = useNavigate();

  // let params = useParams();

  // const filteredData = news.filter((item) => item.title === params.title);
  // // console.log(news.find((item) => item.title === params.title));
  // console.log(filteredData);

  return (
    <div className="NewsDetailWrapper">
      <div className="NewsBackNav">
        <BackNav
          onClick={() => {
            // navigate("/news");
            props.back()
          }}
        />
      </div>

      {/* <FreshContent /> */}

      {/* {filteredData.map((item) => { */}
      {/* return ( */}
      <div>
        <h1 className="NewsTitle">{props.title}</h1>

        <div className="NewsSubDetailsWrapper">
          <p className="NewsSource">{props.source.slice(0,30)}</p>
          <p className="NewsLastUpdated">{`${moment(props.published_at)
            .startOf("hour")
            .fromNow()}`}</p>
        </div>

        <div>{props.description}</div>
        <a href={props.source} target="_blank" rel="noreferrer">Read more </a>
        {/* <iframe
          ref={iref}
          // onLoad={()=> {
          //   setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
          //   console.log({frame: "hi"})
          // }}
          id="myFrame"
          src={props.source}
          height="3000px"
          scrolling="no"
          frameBorder="0"
          width="100%"
          style={{
            maxWidth: 640,
            width: "100%",
            overflow: "auto",
          }}
          
        ></iframe> */}
        {/* <iframe src={props.source} style={{height: "100%", width: "80%" }}></iframe> */}
      </div>
      {/* ); */}
      {/* })} */}
    </div>
  );
};

export default NewsDetail;
