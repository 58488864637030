import React from 'react';
import { Link } from "react-router-dom";
import Logo from "../../assets/images/kudi-logo-white.svg"
import TwiiterIcon from "../../assets/images/twitter-icon.svg"
import FacebookIcon from "../../assets/images/facebook-icon.svg"
import InstagramIcon from "../../assets/images/instagram-icon.svg"

import "./footer.scss"


const Footer = () => {
    return (
        <div className="FooterWrapper">
            <div className="FooterLinksWrapper">
                <Link to="/">
                    <img src={Logo} alt="KudiFX Logo" height="40px" className="FooterLogo"/>
                </Link>

                <div className="FooterTextLinks">
                    <Link to="/about" className="FooterContact"> About </Link>
                    <Link to="/contact" className="FooterAbout"> Contact </Link>
                </div>

                <div className="FooterSocialIconWrapper">
                    <img src={TwiiterIcon} alt="Twiiter Icon" height="35px" />
                    <img src={FacebookIcon} alt="Facebook Icon" height="35px" />
                    <img src={InstagramIcon} alt="Instagram Icon" height="35px" />
                </div>
            </div>

            <p className="FooterNote">
                © 2022 Kudi FX • All rights reserved
            </p>

        </div>
    );
};

export default Footer;
