import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import BlackMarketRates from "./pages/BlackMarketRates";
import CbnRates from "./pages/CbnRates";
import AtmRates from "./pages/AtmRates";
import CryptoRates from "./pages/CryptoRates";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";

const App = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<BlackMarketRates />} />
        <Route path="/cbn-rates" element={<CbnRates />} />
        <Route path="/atm-rates" element={<AtmRates />} />
        <Route path="/crypto-rates" element={<CryptoRates />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:title" element={<NewsDetail />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        {/* <Route
                    path="*"
                    element={<NotFound />}
                /> */}
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
