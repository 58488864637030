import React, { useState, useEffect } from "react";
import axios from "axios";
import PriceContainer from "../../components/PriceContainer";
// import { BMRatesData } from "../../pages/BlackMarketRates/data";
import BuySellText from "../../components/BuySellText";
import Dollars from "../../assets/images/us-dollars.svg";
import Pounds from "../../assets/images/pounds.svg";
import Euros from "../../assets/images/euros.svg";
import Cad from "../../assets/images/canadian-dollars.svg";
import Rand from "../../assets/images/rand.svg";
import Dirham from "../../assets/images/dirham.svg";
import Yuan from "../../assets/images/chinese-yuan.svg";
import Cedis from "../../assets/images/ghana-cedi.svg";
import AUD from "../../assets/images/aud.svg";
import XAF from "../../assets/images/xaf.svg";

import "./blackmarketrates.scss";

const BlackMarketRates = () => {
  const [ratesDash, setRatesDash] = useState([]);
  const [rates, setRates] = useState([]);
  const [server, setServer] = useState("External");

  const images = [
    Dollars, Pounds, Euros, Cad, Rand, Dirham, Yuan, Cedis
  ]
  // require('../../assets/images/us-dollars.svg'), require('../../assets/images/pounds.svg'), require("../../assets/images/euros.svg"), require("../../assets/images/canadian-dollars.svg"), require("../../assets/images/rand.svg"), require("../../assets/images/dirham.svg"), require("../../assets/images/chinese-yuan.svg"), require("../../assets/images/ghana-cedi.svg")

  const GetCbnRates = async () => {
    try {
      const getCbnRatesData = await axios.get(
        "https://kudifx.herokuapp.com/api/v1/get-price"
      );

      const getServer = await axios.get("https://kudifx.herokuapp.com/api/v1/get-server")

      setServer(getServer.data)

      // setServer("External")

      // console.log(getCbnRatesData.data)
      setRatesDash(getCbnRatesData.data.filter(r => !r.atm));
    } catch (error) {
      return error;
    }
  };

  const GetCbnRatesEx = async () => {
    try {
      const getCbnRatesData = await axios.get(
        // "https://abokiforexpaid.appspot.com/_ah/api/myApi/v1/myrates?alt=json"
        "https://abokifx.com/api/v1/rates/movement", {
        headers: {
          Authorization: `Bearer 932e23b715369e6768057205bc2b485e962abf7a`
        }
      }
      )

      const getCbnRatesData2 = await axios.get(
        "https://abokifx.com/api/v1/rates/otherparallel", {
        headers: {
          Authorization: `Bearer 932e23b715369e6768057205bc2b485e962abf7a`
        }
      }
      )

      setRates([...Object.values(getCbnRatesData.data.response)[0].reverse(), ...Object.values(getCbnRatesData2.data.response)[1].reverse(), ...Object.values(getCbnRatesData2.data.response)[0].reverse()]);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    GetCbnRates();
    GetCbnRatesEx();
  }, []);

  // const sendGetRequest = async () => {
  //     try {
  //         const resp = await axios.get('https://jsonplaceholder.typicode.com/posts');
  //         console.log(resp.data);
  //     } catch (err) {
  //         // Handle Error Here
  //         console.error(err);
  //     }
  // };

  // app.get("/prices", function (request, response) {
  //     axios
  //         .get("https://api.nomics.com/v1/prices?key=" + process.env.NOMICS_API_KEY)
  //         .then(resp => {
  //             response.send(resp.data);
  //         })
  //         .catch(err => {
  //             console.log("Error fetching data from nomics", err);
  //         });
  // });

  if (server === "External") {
    return (
      <div className="BlackMarketPageWrapper">
        <h1 className="BlackMarketPageTitle">Black Market Rates</h1>

        {/* Us Dollars */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Dollars} alt="Rates Icon" />
                <p className="CurrencyName">Dollar (USD)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[0]?.currency_rate.slice(0, rates[0]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[0]?.currency_rate.slice(rates[0]?.currency_rate.indexOf('/') + 1, rates[0]?.currency_rate.indexOf('*'))}</p>
                {/* <p>&#8358;1,445</p>
                <p>&#8358;1,460</p> */}
              </div>
            </div>
          }
        />
        {/* British Pounds */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Pounds} alt="Rates Icon" />
                <p className="CurrencyName">Pound (GBP)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[1]?.currency_rate.slice(0, rates[1]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[1]?.currency_rate.slice(rates[1]?.currency_rate.indexOf('/') + 1, rates[1]?.currency_rate.indexOf('*'))}</p>

                {/* <p>&#8358;1,830</p>
                <p>&#8358;1,850</p> */}

              </div>
            </div>
          }
        />
        {/* Euros */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Euros} alt="Rates Icon" />
                <p className="CurrencyName">Euro (EUR)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[2]?.currency_rate.slice(0, rates[2]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[2]?.currency_rate.slice(rates[2]?.currency_rate.indexOf('/') + 1, rates[2]?.currency_rate.indexOf('*'))}</p>
                {/* <p>&#8358;1,520</p> */}
                {/* <p>&#8358;1,540</p> */}
              </div>
            </div>
          }
        />
        {/* Canadian Dollars */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Cad} alt="Rates Icon" />
                <p className="CurrencyName">Dollar (CAD)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[3]?.currency_rate.slice(0, rates[3]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[3]?.currency_rate.slice(rates[3]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;1,000</p>
                <p>&#8358;1,070</p> */}
              </div>
            </div>
          }
        />
        {/* SA Rand */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Rand} alt="Rates Icon" />
                <p className="CurrencyName">RAND (ZAR)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[4]?.currency_rate.slice(0, rates[4]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[4]?.currency_rate.slice(rates[4]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;50</p>
                <p>&#8358;60</p> */}
              </div>
            </div>
          }
        />
        {/* Dirhams */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Dirham} alt="Rates Icon" />
                <p className="CurrencyName">Dirham (AED)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[5]?.currency_rate.slice(0, rates[5]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[5]?.currency_rate.slice(rates[5]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;340</p>
                <p>&#8358;350</p> */}
              </div>
            </div>
          }
        />
        {/* Chinese Yuan */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Yuan} alt="Rates Icon" />
                <p className="CurrencyName">Yuan (CNY)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[6]?.currency_rate.slice(0, rates[6]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[6]?.currency_rate.slice(rates[6]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;160</p>
                <p>&#8358;175</p> */}
              </div>
            </div>
          }
        />
        {/* AUD */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={AUD} alt="Rates Icon" style={{
                  borderRadius: "50%",
                  height: "70px",
                  width: "70px"
                }} />
                <p className="CurrencyName">Australian Dollar(AUD)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[7]?.currency_rate.slice(0, rates[7]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[7]?.currency_rate.slice(rates[7]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;110</p>
                <p>&#8358;120</p> */}
              </div>
            </div>
          }
        />

        {/* Ghana Cedis */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Cedis} alt="Rates Icon" />
                <p className="CurrencyName">G.Cedi (GHS)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[8]?.currency_rate.slice(0, rates[8]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[8]?.currency_rate.slice(rates[8]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;110</p>
                <p>&#8358;120</p> */}
              </div>
            </div>
          }
        />

        {/* XAF Cedis */}
        <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={XAF} alt="Rates Icon" />
                <p className="CurrencyName">Central African (XAF)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[9]?.currency_rate.slice(0, rates[9]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[9]?.currency_rate.slice(rates[9]?.currency_rate.indexOf('/') + 1)}</p>
                {/* <p>&#8358;110</p>
                <p>&#8358;120</p> */}
              </div>
            </div>
          }
        />

        {/* XAF Cedis */}
        {/* <PriceContainer
          content={
            <div className="BmPriceContainerWrapper">
              <div className="CurrencyWrapper">
                <img src={Cedis} alt="Rates Icon" />
                <p className="CurrencyName">West African (XAF)</p>
              </div>

              <BuySellText />

              <div className="CurrencyPrice">
                <p>&#8358;{rates[10]?.currency_rate.slice(0, rates[10]?.currency_rate.indexOf('/'))}</p>
                <p>&#8358;{rates[10]?.currency_rate.slice(rates[10]?.currency_rate.indexOf('/') + 1)}</p>
                
              </div>
            </div>
          }
        /> */}

      </div>
    );
  }

  return (
    <div className="BlackMarketPageWrapper">
      <h1 className="BlackMarketPageTitle">Black Market Rates</h1>

      {/* Us Dollars */}
      {ratesDash && ratesDash[0] && (
        <div>
          {ratesDash.map((rate, ix) => (
            <PriceContainer
              key={ix}
              content={
                <div className="BmPriceContainerWrapper">
                  <div className="CurrencyWrapper">
                    <img src={images[ix]} style={{ width: 35 }} alt="Rates Icon" />
                    <p className="CurrencyName">{rate.currency_name} ({rate.currency_symbol})</p>
                  </div>

                  <BuySellText />

                  <div className="CurrencyPrice">
                    <p>&#8358;{rate.buy_price}</p>
                    <p>&#8358;{rate.sell_price}</p>
                  </div>
                </div>
              }
            />
          ))}



        </div>
      )
      }
    </div>
  );
};

export default BlackMarketRates;
